<template>
  <div class="virtual-detail-history">
    <transition name="fade" mode="out-in">
      <base-table
        v-if="history.length"
        :list="sortedList"
        :columns="tableHead"
        :sort="sort"
        class="standart-text"
        @sort-change="onSortChange"
      >
        <base-table-row v-for="(item, i) in sortedList" :key="i" :item="item" :columns="tableHead">
          <template #desc>
            {{ item.desc }}
          </template>
          <template #date>
            {{ format(item.changedate) }}
          </template>
          <template #user>
            {{ item.user }}
          </template>
        </base-table-row>
      </base-table>
      <base-loader v-else />
    </transition>
  </div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable.vue';
import BaseTableRow from '@/components/Table/BaseTableRow.vue';
import { sortFuncsByOrder } from '@/utils/sortFunctions';
import { format } from 'date-fns';
import { VirtualTariff } from '@/models/BillMgr/VirtualTariff';
export default {
  name: 'VpsHistory',
  components: { BaseTable, BaseTableRow },
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof VirtualTariff,
    },
  },
  data() {
    return {
      tableHead: [
        {
          key: 'desc',
          label: this.$t('desc'),
          style: {
            width: '33%',
            minWidth: '160px',
          },
        },
        {
          key: 'date',
          label: this.$t('date'),
          style: {
            width: '33%',
            minWidth: '135px',
          },
          sort: {
            prop: 'changedate',
            order: 'asc',
          },
        },
        {
          key: 'user',
          label: this.$t('user'),
          style: {
            width: '33%',
            minWidth: '132px',
          },
        },
      ],
      sort: {
        prop: 'changedate',
        order: 'desc',
      },
    };
  },
  computed: {
    history() {
      return this.tariff.history;
    },
    sortedList() {
      let list = [...this.history];
      const { prop, order } = this.sort;
      list.sort((a, b) => sortFuncsByOrder[order](a[prop], b[prop]));
      return list;
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch(id = this.tariff.id) {
      this.$store.dispatch('moduleVirtual/fetchHistory', id);
    },
    onSortChange(payload) {
      const { prop, order } = payload;
      if (this.sort.prop === prop) {
        this.sort.order = order === 'asc' ? 'desc' : 'asc';
      } else {
        this.sort = payload;
      }
      this.updateSort(this.sort);
    },
    updateSort(sort) {
      const col = this.tableHead.find(i => i.sort && i.sort.prop === sort.prop);
      col.sort.order = sort.order;
    },
    format(date, tpl) {
      tpl = tpl || 'dd.MM.yyyy HH:mm:ss';
      return format(new Date(date), tpl);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "desc": "Что произошло",
    "date": "Когда",
    "user": "Инициатор"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
.virtual-detail-history {
  margin-top: 1.5rem;

  +breakpoint(sm-and-up) {
    margin-top: 2.5rem;
  }
}
</style>
